<template>
  <div>
    <h2 class="th-title">Account</h2>
    <a-card>
      <div class="d-flex justify-content-between mb-3">
        <div class="">
          <a-input
            style="width: 200px"
            v-model:value="search"
            @change="handleSearchAkun"
            placeholder="Cari berdasarkan nama"
          >
            <template #prefix>
              <span>
                <search-outlined style="color: rgba(0, 0, 0, 0.25)" />
              </span>
            </template>
          </a-input>
          <a-select
            class="ml-2"
            style="width: 200px;"
            v-model:value="category"
            @change="handleChangeCategory"
          >
            <a-select-option value="all">
              Semua Plan
            </a-select-option>
            <a-select-option value="632a97a29d26e43fc05e1f50">
              Silver Plan
            </a-select-option>
            <a-select-option value="6253f147f0cb964a1238e3f0">
              Gold Plan
            </a-select-option>
            <a-select-option value="632a99b39d26e43fc05e1f54" disabled>
              Platinum Plan
            </a-select-option>
          </a-select>
        </div>
        <a-button @click.prevent="showModal(null, 'ADD')">
          <template #icon><plus-outlined /></template>
          Tambahkan Akun
        </a-button>
        <a-modal v-model:visible="modalVisible" title="Add Account" @ok="handleOk">
          <template v-if="modalView === 'ADD'">
            <a-form layout="vertical" :model="formAdd">
              <a-form-item
                required
                label="Nama Depan"
                name="firstName"
                v-bind="validateInfos.firstName"
              >
                <a-input placeholder="Masukkan nama depan" v-model:value="formAdd.firstName" />
              </a-form-item>
              <a-form-item
                required
                label="Nama Belakang"
                name="lastName"
                v-bind="validateInfos.lastName"
              >
                <a-input placeholder="Masukkan nama belakang" v-model:value="formAdd.lastName" />
              </a-form-item>
              <a-form-item
                required
                label="Username"
                name="userName"
                v-bind="validateInfos.userName"
              >
                <a-input placeholder="Masukkan username" v-model:value="formAdd.userName" />
              </a-form-item>
              <a-form-item required label="Email" name="email" v-bind="validateInfos.email">
                <a-input placeholder="Masukkan Email" v-model:value="formAdd.email" />
              </a-form-item>
              <a-form-item
                required
                label="Password bawaan"
                name="password"
                v-bind="validateInfos.password"
              >
                <a-input
                  placeholder="Password bawaan"
                  v-model:value="formAdd.password"
                  :disabled="true"
                />
              </a-form-item>
            </a-form>
          </template>
          <template #footer>
            <a-button @click.prevent="confirm" key="submit" type="primary" html-type="submit">{{
              modalView === 'ADD' ? 'Tambahkan' : 'Perbaharui'
            }}</a-button>
          </template>
        </a-modal>
      </div>
      <div>
        <a-table
          :class="$style.table"
          :columns="columns"
          :data-source="dataTable"
          :pagination="globalPagination"
          :loading="loadingTable"
          @change="handleTableChange"
          bordered
        >
          <template #name="{ record }">
            <span>
              <router-link
                class="text-primary kit__utils__link"
                :to="{ name: 'Detail Akun', params: { id: record._id } }"
                >{{ record.fullName || record.userDetail.fullName }}</router-link
              >
            </span>
          </template>
          <template #userName="{ record }">
            <span>
              {{ record.userDetail.username }}
            </span>
          </template>
          <template #email="{ text }">
            <span>
              {{ text }}
            </span>
          </template>
          <template #status="{ record }">
            <div>
              <a-tag
                class="align-self-start"
                v-if="record.userDetail.creatorVerificationStatus === 'success'"
                color="success"
              >
                <template #icon>
                  <check-circle-outlined />
                </template>
                Sudah Terverifikasi
              </a-tag>
              <a-tag
                class="align-self-start"
                v-else-if="record.userDetail.creatorVerificationStatus === 'on_progress'"
                color="processing"
              >
                <template #icon>
                  <close-circle-outlined />
                </template>
                Perlu di Verifikasi
              </a-tag>
              <a-tag class="align-self-start" v-else color="error">
                <template #icon>
                  <close-circle-outlined />
                </template>
                Gagal Verifikasi
              </a-tag>
            </div>
          </template>
          <template #action="{ }">
            <span>
              <!-- <a @click.prevent="vendorAction(record, 'DETAIL')">Detail</a> -->
              <a>Hapus Akun</a>
            </span>
          </template>
          <template #plan="{record}">
            <a-tag
              class="align-self-start"
              v-if="record.creatorPlan?.name === 'Silver Plan'"
              color="#C0C0C0"
            >
              {{ record.creatorPlan.name }}
            </a-tag>
            <a-tag
              class="align-self-start"
              v-else-if="record.creatorPlan?.name === 'Gold Plan'"
              color="#FFD700"
            >
              {{ record.creatorPlan.name }}
            </a-tag>
            <a-tag
              class="align-self-start"
              v-else-if="record.creatorPlan?.name === 'Platinum Plan'"
              color="#E5E4E2"
            >
              {{ record.creatorPlan.name }}
            </a-tag>
          </template>
        </a-table>
      </div>
    </a-card>
  </div>
</template>

<script lang="js">
import { onMounted, reactive, ref, toRaw,computed } from 'vue';
import { useStore } from 'vuex';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { Modal, notification } from 'ant-design-vue';
import { ExclamationCircleOutlined, CheckOutlined, CloseOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons-vue';
import { createVNode } from 'vue';
import { useForm } from '@ant-design-vue/use';
import {usePagination} from 'vue-request'
import axios from 'axios';
const columns = [
  {
    title: 'Nama',
    dataIndex: 'name',
    key: 'name',
    slots: { customRender: 'name' },
  },
  {
    title: 'Username',
    dataIndex: 'userName',
    key: 'userName',
    slots: { customRender: 'userName' },
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    slots: { customRender: 'email' },
  },
  // {
  //   title: 'Nomor KTP',
  //   dataIndex: 'ktp',
  //   key: 'ktp',
  //   slots: { customRender: 'ktp' },
  //   align: 'center',
  // },
  {
    title: 'Plan',
    dataIndex: 'plan',
    key: 'plan',
    slots: { customRender: 'plan' },
    align: 'center',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    slots: { customRender: 'status' },
    align: 'center',
  },
  // {
  //   title: 'Aksi',
  //   key: 'action',
  //   slots: { customRender: 'action' },
  //   align: 'center',
  // },
];

export default {
  components: {
    SearchOutlined,
    PlusOutlined,
    // CheckOutlined,
    // CloseOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
  },
  setup() {
    const store = useStore()

    // const {
    //   data: dataSource,
    //   run,
    //   loading,
    //   current,
    //   pageSize,
    // } = usePagination(fetchAkun, {
    //   formatResult: res => res.data,
    //   pagination: {
    //     currentKey: 'page',
    //     pageSizeKey: 'results',
    //   },
    // });
    let dataTable = ref([])
    let globalPagination = ref({ current: 1, pageSize: 5 })
    let search = ref('')
    let sortBy = ref('createdAt')
    let order = ref('desc')
    let category = ref('all')
    let loadingTable = ref(false)
    let modalView = ref(null)
    let modalVisible = ref(false)
    let formAdd = reactive({
      firstName: null,
      lastName: null,
      userName: null,
      email: null,
      password: null,
    })
    let idEdit = ref(null)
    const loadingAdd = ref(false)
    const rulesRef = reactive({
      firstName: [
        {
          required: true,
          message: 'Nama Depan harus diisi!',
          trigger: 'change',
        },
      ],
      lastName: [
        {
          required: true,
          message: 'Nama Belakang harus diisi!',
          trigger: 'change',
        },
      ],
      email: [
        {
          required: true,
          message: 'Email harus diisi!',
          trigger: 'change',
        },
      ],
      password: [
        {
          required: true,
          message: 'Password harus diisi!',
          trigger: 'change',
        },
      ],
    })
    const rulesPasswordRef = reactive({
      password: [
        {
          required: true,
          message: 'Password harus diisi!',
          trigger: 'change',
        },
      ],
      confirmPassword: [
        {
          required: true,
          message: 'Konfirmasi Password harus diisi!',
          trigger: 'change',
        },
      ],
    })

    onMounted(() => {
      fetchAkun({creatorPlanId:category.value==='all'?'':category.value})
    })
    const fetchAkun = async  (params) => {
      loadingTable.value = true
      store.commit('akun/SET_STATE_PER_FIELD',{type: 'menuSelected',data: 'Detail Akun'})
      const apiFetchAllAkun = await store.dispatch('akun/ADMIN_FETCH_ALL_USER',{
        search:search.value,
        page: globalPagination.value.current,
        limit: globalPagination.value.pageSize,
        sortBy: sortBy.value,
        order: order.value,...params})
      if (apiFetchAllAkun) {
        const { data, totalAllData } = apiFetchAllAkun
        globalPagination.value = { ...globalPagination.value, total: totalAllData }

        dataTable.value = data.map(el => {
          return {
            key: el._id,
            ...el,
          }
        })
        console.log(data, totalAllData)
      }
      loadingTable.value = false
      // return axios({
      //   url:`${process.env.VUE_APP_BACKEND_URL}api/v1/admin/all-user`,
      //   method:'get',
      //   params:{...params},
      //   header:{
      //     token:'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzN2YxMTQ3MDRhZTRmNDA1MGM3ZjNkMiIsImVtYWlsIjoibWVsdGFhcHBzQGdtYWlsLmNvbSIsImlhdCI6MTY2OTYwMzEyMCwiZXhwIjoxNjcyMTk1MTIwfQ.xdR5lVGTDdg7uDqqPONpMnpvY67wWLPepi20BGC9QRI'
      //   }
      // }
      // )
      // loadingTable.value = true
      // const rawData = await store.dispatch('akun/FETCH_ALL_AKUN_CUSTOMER', search)
      // loadingTable.value = false
      // data.value = rawData.map(el => {
      //   const name = el.firstName + ' ' + (el.lastName || '')
      //   return {
      //     key: el._id,
      //     name,
      //     ...el,
      //   }
      // })

      // data.value = store.state.akun.accounts
    }
    const handleTableChange = (pag, filters, sorter) => {
      globalPagination.value.current = pag.current
      if (sorter) {
        if (sorter.columnKey == 'key') sortBy.value = '_id'
        else sortBy.value = sorter.columnKey
        order.value = sorter.order
      }
      fetchAkun({creatorPlanId: category.value==='all'?'':category.value})
    };



    const showModal = (record = null, type) => {
      if (type === 'ADD') {
        formAdd.password = Math.random().toString(36).slice(2)
      }

      if (record) {
        idEdit.value = record.key
      }
      modalView.value = type
      modalVisible.value = true
    }

    const handleOk = () => {
      modalVisible.value = false
    }

    const { resetFields, validate, validateInfos } = useForm(formAdd, rulesRef);

    const confirm = async () => {
      try {
        if (modalView.value === 'ADD') {
          await validate()
        // console.log(console.log(toRaw(formAddRef)))
          Modal.confirm({
            title: 'Apa anda yakin ingin menambahkan akun?',
            icon: createVNode(ExclamationCircleOutlined),
            async onOk() {
              try {
                // const data = await store.dispatch('akun/ADD_AKUN_CUSTOMER', { ...formAdd })
                store.state.akun.accounts.push({...formAdd})
                modalVisible.value = false
                fetchAkun()
                notification.success({
                  message: `Berhasil Menambahkan Akun`,
                })
                // resetFields()
              } catch (err) {
                console.log(err)
              }
            },
            onCancel() {},
          })
        }
      } catch(err) {}
    }

    const vendorAction = async (record, action) => {
      const data = {
        id: record.key,
      }
      if (action === 'DELETE') {
        Modal.confirm({
          title: `Apa anda yakin ingin menghapus vendor '${record.name}' dari daftar?`,
          icon: createVNode(ExclamationCircleOutlined),
          async onOk() {
            try {
              const res = await store.dispatch('akun/DELETE_AKUN_CUSTOMER', { ...data })
              fetchAkun()
              notification.success({
                message: `Berhasil Menghapus Akun`,
              })
            } catch (err) {
              console.log(err)
            }
          },
          onCancel() {},
        })
      }
    }

    const handleSearchAkun = () => {
      globalPagination.value.current = 1
      fetchAkun({creatorPlanId:category.value==='all'?'':category.value})
    }
    const handleChangeCategory = ()=>{
      globalPagination.value.current = 1
      fetchAkun({creatorPlanId:category.value==='all'?'':category.value})
    }

    return {
      dataTable,
      columns,
      category,
      loadingTable,
      search,
      modalView,
      modalVisible,
      showModal,
      handleOk,
      formAdd,
      rulesRef,
      rulesPasswordRef,
      loadingAdd,
      confirm,
      validate,
      validateInfos,
      vendorAction,
      idEdit,
      handleSearchAkun,
      globalPagination,
      handleTableChange,
      handleChangeCategory,
    }
  },
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>
